<template>
    <div>
        <header-slot/>
        <list-table-client-company />
    </div>
</template>

<script>
import ListTableClientCompany from '@/views/commons/components/list-client-company/list-table/ClientCompanyTable.vue'
export default {
    components: {ListTableClientCompany}
}
</script>