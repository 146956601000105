export default [
    {
      key: 'lead_name',
      sortable: true,
      label: 'Name',
      tdClass: 'align-middle'
    },
    {
      key: 'packs',
      sortable: false,
      label: 'Pack',
    },
    {
      key: 'name_company',
      sortable: true,
      label: 'Company',
    },
    {
      key: 'biz_type',
      sortable: true,
      label: 'Industry',
    },
    {
      key: 'last_bor',
      sortable: true,
      label: 'DOR',
    },
    {
      key: 'ein_company_secret',
      label: 'EIN',
    },
    {
      key: 'last_ir',
      sortable: true,
      label: 'IR',
    },
    {
      key: 'last_ca',
      sortable: true,
      label: 'C.A.',
    },
    {
        key: 'name_benefit',
        sortable: false,
        label: 'T&I',
    },
    {
        key: 'advisor_name',
        sortable: true,
        label: 'Advisor',
    },
    {
        key: 'created_note',
        sortable: false,
        label: 'LN',
    },
]