export default [
    {
      type: 'select',
      margin: true,
      showLabel: true,
      label: 'Advisor',
      options: [
        { id: 0, user_name: 'All' },
      ],
      model: null,
      reduce: 'id',
      selectText: 'user_name',
      cols: 12,
      md: 2,
      visible: true,
    },
    {
      type: 'select',
      label: 'Packs',
      margin: true,
      showLabel: true,
      options: [],
      model: '',
      reduce:'id_packs',
      selectText: 'description',
      cols: 12,
      md: 2,
      visible: true,
    },
]