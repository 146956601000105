<template>
  <div>
    <b-card no-body class="mb-2">
      <div>
        <filter-slot
          :filter="filter"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reset-all-filters="resetAllFilters()"
          @reload="refreshTable()"
        >
          <template #table>
            <b-table
              id="companyList"
              slot="table"
              ref="companyList"
              :items="search"
              sticky-header="50vh"
              primary-key="account_id"
              show-empty
              :fields="filteredFields"
              :sort-direction="order"
              :busy.sync="isBusy"
              :per-page="paginate.perPage"
              :current-page="paginate.currentPage"
              @sort-changed="orderChange()"
              small
              responsive="sm"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template v-slot:cell(lead_name)="data">
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-start
                  "
                >
                  <router-link
                    :class="[textLink]"
                    v-if="moduleId === 12"
                    :to="`/ti/clients/account/${data.item.account_id}`"
                    target="_blank"
                    >{{ data.item.lead_name }}</router-link
                  >
                  <router-link
                    :class="[textLink]"
                    v-if="moduleId === 3"
                    target="_blank"
                    :to="`/bussiness/clients/account/${data.item.account_id}`"
                    >{{ data.item.lead_name }}</router-link
                  >
                  {{ data.item.mobile }}
                </div>
              </template>
              <template v-slot:cell(packs)="data">
                <span v-for="(pack, index) in data.item.packs" :key="index">{{
                  pack.name_pack
                }}</span>
              </template>
              <template v-slot:cell(last_bor)="data">
                <b-form-checkbox
                  v-model="data.item.bor"
                  @change="capsuleCompany(1, data.item)"
                  switch
                  >Yes</b-form-checkbox
                >
                <span>{{ data.item.last_bor | myGlobalDay }}</span>
              </template>

              <template v-slot:head(ein_company_secret)>
                <span
                  >EIN
                  <feather-icon
                    v-if="isCeo || isSupervisor || isChief"
                    size="17"
                    :icon="viewEin ? 'EyeIcon' : 'EyeOffIcon'"
                    @click="viewEinCompany()"
                  />
                </span>
              </template>
              <template v-slot:cell(ein_company_secret)="data">
                <span v-if="!viewEin">{{ data.item.ein_company_secret }}</span>
                <span v-if="viewEin">{{ data.item.ein_company }}</span>
              </template>
              <template v-slot:cell(last_ir)="data">
                <b-form-checkbox
                  v-model="data.item.ir"
                  @change="capsuleCompany(2, data.item)"
                  switch
                  >Yes</b-form-checkbox
                >
                <span>{{ data.item.last_ir | myGlobalDay }}</span>
              </template>
              <template v-slot:cell(last_ca)="data">
                <b-form-checkbox
                  v-model="data.item.ca"
                  @change="capsuleCompany(3, data.item)"
                  switch
                  >Yes</b-form-checkbox
                >
                <span>{{ data.item.last_ca | myGlobalDay }}</span>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import ListClientCompanyService from "../services/list-client-company.service";
import { BFormCheckboxGroup } from "bootstrap-vue";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import dataFields from "./fields.data";
import dataFilter from "./filters.data";
export default {
  components: {
    FilterSlot,
    vSelect,
    BFormCheckboxGroup,
  },
  data() {
    return {
      filter: dataFilter,
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "account_id",
      startPage: null,
      toPage: null,
      isBusy: false,
      items: [],
      viewEin: false,
      fields: dataFields,
      activesglobal: "",
      order: "desc",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    filteredFields() {
      return this.fields;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.setFilterPacks();
  },
  methods: {
    orderChange() {
      this.order === "asc" ? "desc" : "asc";
    },
    refreshTable() {
      this.$refs["companyList"].refresh();
    },
    async setFilterPacks() {
      const data = await ListClientCompanyService.nonRepeatingPacksFilter();
      this.filter[1].options =
        data.status === 200 ? JSON.parse(JSON.stringify(data.data)) : [];
      const dataAdvisor = await ListClientCompanyService.usersPrograms({
        idmodule: 3,
        iduser: this.currentUser.user_id,
        idrole: this.currentUser.role_id,
      });
      this.filter[0].options =
        dataAdvisor.status === 200
          ? JSON.parse(JSON.stringify(dataAdvisor.data))
          : [];
    },
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.companyList.refresh();
    },
    viewEinCompany() {
      this.viewEin = !this.viewEin;
    },
    async capsuleCompany(type, data) {
      this.activesglobal =
        type === 1
          ? data.bor
          : type === 2
          ? data.ir
          : type === 3
          ? data.ca
          : "";
      const swal = await this.showConfirmSwal(
        "Update status?",
        "Are you sure to make the modification?"
      );
      if (swal.isConfirmed) {
        this.addPreloader();
        try {
          const response = await ListClientCompanyService.refreshcapsulecompany(
            {
              user_id: this.currentUser.role_id,
              client_id: data.account_id,
              capsule: this.activesglobal,
              typee: type,
            }
          );
          if (response.status === 200) {
            this.$refs.companyList.refresh();
            this.showToast("success", "top-right", "Updated!", "CheckIcon");
            this.removePreloader();
          }
        } catch (e) {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
        }
      } else {
        if (type == 1) {
          data.bor = !data.bor;
        } else if (type == 2) {
          data.ir = !data.ir;
        } else if (type == 3) {
          data.ca = !data.ca;
        }
      }
    },
    async search(ctx) {
      try {
        let sortBy = 5;
        let sortDirection = this.order;
        if (ctx.sortBy === "lead_name") {
          sortBy = 10;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "name_company") {
          sortBy = 3;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "advisor_name") {
          sortBy = 11;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "biz_type") {
          sortBy = 6;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "last_bor") {
          sortBy = 7;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "last_ir") {
          sortBy = 8;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "last_ca") {
          sortBy = 9;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        }
        const params = {
          perPage: ctx.perPage,
          orderby: sortBy,
          order: sortDirection,
          from: "",
          to: "",
          text: this.filterPrincipal.model,
          advisor: this.filter[0].model,
          packs: this.filter[1].model,
          modul: this.modul,
        };
        const data = await ListClientCompanyService.searchclientcompanydata(
          params,
          ctx.currentPage
        );

        data.data.map((data) => {
          // eslint-disable-next-line no-param-reassign
          data.bor = data.bor === 1 ? true : false;
          data.ir = data.ir === 1 ? true : false;
          data.ca = data.ca === 1 ? true : false;
        });
        this.items = data.data;
        // Must return an array of items or an empty array if an error occurred

        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalData = data.total;
        this.totalRows = data.total;
        this.toPage = data.to;

        return this.items || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>